<template>
  <div :class="className" :style="{height:height,width:width}"/>
</template>

<script>
import echarts from 'echarts'

require('echarts/theme/macarons')
import {debounce} from '@/utils'

export default {
  name: "Retention",
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data() {
    return {
      chart: null,
      queryForm: {
        startTime: '',
        endTime: ''
      },
    }
  },
  mounted() {
    this.chart = echarts.init(this.$el, 'macarons')
    this.__resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize()
      }
    }, 100)
    window.addEventListener('resize', this.__resizeHandler)
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    window.removeEventListener('resize', this.__resizeHandler)
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    refreshCart(queryForm) {
      this.queryForm = queryForm
      this.initChart()
    },
    initChart() {
      this.$http({
        url: this.$http.adornUrl("/admin/report/retained"),
        method: "POST",
        data: this.queryForm
      }).then(({data}) => {
        if (data && data.code === 0) {

          const retentionReport = data.result
          const day = []
          const retainedUsers1 = []
          const retainedUsers3 = []
          const retainedUsers5 = []
          const retainedUsers7 = []
          const retainedUsers14 = []
          const retainedUsers30 = []
          const retainedUsers60 = []
          const retainedUsers90 = []

          retentionReport.forEach(function (item) {
            day.push(item.statDate)
            retainedUsers1.push(item.retainedUsers1)
            retainedUsers3.push(item.retainedUsers3)
            retainedUsers5.push(item.retainedUsers5)
            retainedUsers7.push(item.retainedUsers7)
            retainedUsers14.push(item.retainedUsers14)
            retainedUsers30.push(item.retainedUsers30)
            retainedUsers60.push(item.retainedUsers60)
            retainedUsers90.push(item.retainedUsers90)
          })
          this.chart.setOption({
            title: {
              text: '用户留存数'
            },
            tooltip: {
              trigger: 'axis'
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            legend: {
              width: 350,
              data: ['次留', '3留', '5留', '7留', '14留', '30留', '60留', '90留']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [{
              type: 'category',
              data: day,
              boundaryGap: false
            }],
            yAxis: [{
              type: 'value'
            }],
            series: [
              {
                name: '次留',
                type: 'line',
                data: retainedUsers1
              },
              {
                name: '3留',
                type: 'line',
                data: retainedUsers3
              },
              {
                name: '5留',
                type: 'line',
                data: retainedUsers5
              },
              {
                name: '7留',
                type: 'line',
                data: retainedUsers7
              },
              {
                name: '14留',
                type: 'line',
                data: retainedUsers14
              },
              {
                name: '30留',
                type: 'line',
                data: retainedUsers30
              },
              {
                name: '60留',
                type: 'line',
                data: retainedUsers60
              },
              {
                name: '90留',
                type: 'line',
                data: retainedUsers90
              }
            ]
          })
        } else {
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err);
      })


    }
  }
}
</script>
